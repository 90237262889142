import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1 className="site-title mt-5 mb-5">
        <Link to={`/`}>
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3 className="site-title mt-3 mb-3">
        <Link to={`/`}>
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <Container>
      <header>
        <Row>
          <Col>
            {header}
          </Col>
        </Row>
      </header>
      <main>
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
      </main>
      <footer>
        <Row>
          <Col>
            © {new Date().getFullYear()} Tejas Jadhav
          </Col>
        </Row>
      </footer>
    </Container>
  )
}

export default Layout
